import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Parse from 'parse';

const EmailVerify = () => {
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkEmailVerification = async () => {
      const currentUser = Parse.User.current();
      if (!currentUser) {
        navigate('/login');
        return;
      }

      try {
        await currentUser.fetch();
        if (currentUser.get('emailVerified')) {
          navigate('/home');
        } else {
          setIsChecking(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    checkEmailVerification();

    const interval = setInterval(checkEmailVerification, 3000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <p className="text-2xl font-bold">Please verify your email address.</p>
      {!isChecking && (
        <p className="text-lg mt-4">We are checking your verification status...</p>
      )}
      <div className="mt-64" />
    </div>
  );
};

export default EmailVerify;
