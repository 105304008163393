import Parse from "parse";

export const getCompanyList = async () => {
    const currentUser = Parse.User.current();
    const apiKey = currentUser.get('apiKey');
    
    try {
        const response = await fetch('https://mfov7ppgssofjgwsvleqj7ac4q0njepc.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "user_api_key": apiKey,
                "api_key": process.env.REACT_APP_MASTER_API_KEY,
                "email": currentUser.get('email'),
                "operation_index": "5"
            }),
        });

        const data = (await response.json()).company_list;

        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};



export const gatherInformation = async (base_url) => {
    const currentUser = Parse.User.current();
    const apiKey = currentUser.get('apiKey');

    try {
        const response = await fetch('https://mfov7ppgssofjgwsvleqj7ac4q0njepc.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "user_api_key": apiKey,
                "api_key": process.env.REACT_APP_MASTER_API_KEY,
                "base_url": base_url,
                "email": currentUser.get('email'),
                "operation_index": "1"
            }),
        });

        // Optionally, you can also log the JSON content of the response
        await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const getIndividualCompanyData = async (base_url) => {
    const currentUser = Parse.User.current();
    const apiKey = currentUser.get('apiKey');

    try {
        const response = await fetch('https://mfov7ppgssofjgwsvleqj7ac4q0njepc.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "user_api_key": apiKey,
                "api_key": process.env.REACT_APP_MASTER_API_KEY,
                "base_url": base_url,
                "operation_index": "2"
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.search_data;
    } catch (error) {
        // console.error('Error fetching individual company data:', error);
    }
};


export const getNotes = async (base_url) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        console.error("No current user found. Please ensure the user is authenticated.");
        return [];
    }

    const Note = Parse.Object.extend("Note");
    const query = new Parse.Query(Note);
    query.equalTo("userId", currentUser); // Ensure you use the correct field from the user object
    query.equalTo("noteUrl", base_url);

    try {
        const results = await query.find();
        return results.map(result => ({
            blockTitle: result.get('blockTitle'),
            noteBody: result.get('noteBody'),
        }));
    } catch (error) {
        console.error("Error fetching notes:", error);
        return [];
    }
};

export const saveNotes = async (base_url, notes) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        console.error("No current user found. Please ensure the user is authenticated.");
        return;
    }

    const Note = Parse.Object.extend("Note");

    try {
        for (let noteData of notes) {
            const query = new Parse.Query(Note);
            query.equalTo("userId", currentUser);
            query.equalTo("noteUrl", base_url);
            query.equalTo("blockTitle", noteData.blockTitle);

            const existingNote = await query.first();

            let note;
            if (existingNote) {
                note = existingNote;
            } else {
                note = new Note();
                note.set("userId", currentUser);
                note.set("noteUrl", base_url);
                note.set("blockTitle", noteData.blockTitle);
            }

            note.set("noteBody", noteData.noteBody);
            await note.save();
        }
        console.log("All notes saved successfully.");
    } catch (error) {
        console.error("Error saving notes:", error);
    }
};


export const getFrontendBlocks = async (base_url) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        console.error("No current user found. Please ensure the user is authenticated.");
        return [];
    }

    const FrontendBlock = Parse.Object.extend("FrontendBlock");
    const query = new Parse.Query(FrontendBlock);
    query.equalTo("userId", currentUser); // Ensure you use the correct field from the user object
    query.equalTo("baseUrl", base_url);

    try {
        const results = await query.find();
        return results.map(result => ({
            blockTitle: result.get('blockTitle'),
            blockBody: result.get('blockBody'),
        }));
    } catch (error) {
        console.error("Error fetching frontend blocks:", error);
        return [];
    }
};

export const saveFrontendBlocks = async (base_url, frontendBlocks) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        console.error("No current user found. Please ensure the user is authenticated.");
        return;
    }

    const FrontendBlock = Parse.Object.extend("FrontendBlock");

    try {
        for (let blockData of frontendBlocks) {
            const query = new Parse.Query(FrontendBlock);
            query.equalTo("userId", currentUser);
            query.equalTo("baseUrl", base_url);
            query.equalTo("blockTitle", blockData.blockTitle);

            const existingBlock = await query.first();

            let block;
            if (existingBlock) {
                block = existingBlock;
            } else {
                block = new FrontendBlock();
                block.set("userId", currentUser);
                block.set("baseUrl", base_url);
                block.set("blockTitle", blockData.blockTitle);
            }

            block.set("blockBody", blockData.blockBody);
            await block.save();
        }
        console.log("All frontend blocks saved successfully.");
    } catch (error) {
        console.error("Error saving frontend blocks:", error);
    }
}


export const getTagList = async (base_url) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        console.error("No current user found. Please ensure the user is authenticated.");
        return [];
    }

    const TagList = Parse.Object.extend("TagList");
    const query = new Parse.Query(TagList);
    query.equalTo("userId", currentUser); // Ensure you use the correct field from the user object
    query.equalTo("baseUrl", base_url);

    try {
        const results = await query.find();
        if (results.length === 0) {
            return [];
        } else {
            if (results[0].get('tagList') === '') {
                return [];
            } else {
                return results[0].get('tagList');
            }
        }
    } catch (error) {
        console.error("Error fetching tags:", error);
        return [];
    }
}

export const saveTagList = async (base_url, tagList) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        console.error("No current user found. Please ensure the user is authenticated.");
        return;
    }

    const TagList = Parse.Object.extend("TagList");

    try {
        const query = new Parse.Query(TagList);
        query.equalTo("userId", currentUser);
        query.equalTo("baseUrl", base_url);

        const existingTagList = await query.first();

        let tagListObject;
        if (existingTagList) {
            tagListObject = existingTagList;
        } else {
            tagListObject = new TagList();
            tagListObject.set("userId", currentUser);
            tagListObject.set("baseUrl", base_url);
        }

        tagListObject.set("tagList", tagList);
        await tagListObject.save();
        console.log("Tag list saved successfully.");
    } catch (error) {
        console.error("Error saving tag list:", error);
    }
}


export const deleteCompany = async (base_url) => {
    const currentUser = Parse.User.current();
    const apiKey = currentUser.get('apiKey');

    try {
        const response = await fetch('https://mfov7ppgssofjgwsvleqj7ac4q0njepc.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "user_api_key": apiKey,
                "api_key": process.env.REACT_APP_MASTER_API_KEY,
                "base_url": base_url,
                "operation_index": "6"
            }),
        });
        console.log("Delete response: ", response);
    } catch (error) {
        console.error('Error deleting company:', error);
    }
};
