import React, { useState, useEffect, useRef } from 'react';
import { getIndividualCompanyData, getNotes, saveNotes, getFrontendBlocks, saveFrontendBlocks, saveTagList, gatherInformation } from './HomeService.js';
import { useLocation } from 'react-router-dom';

const IndividualCompany = () => {
    const location = useLocation();
    const currentUrl = location.pathname.replace('/home/', '');
    const fullUrl = `https://${currentUrl}`;

    const [tag, setTag] = useState('');
    const [tagToRemove, setTagToRemove] = useState(null);
    const [isCreateTagModalOpen, setIsCreateTagModalOpen] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const [companyName, setCompanyName] = useState('');

    const [tags, setTags] = useState([]);
    const [emails, setEmails] = useState([]);
    const [urls, setUrls] = useState([]);
    const [dateSourced, setDateSourced] = useState('');
    const [sections, setSections] = useState([]);
    const [frontendSections, setFrontendSections] = useState([]);

    const [isCreateBlockModalOpen, setIsCreateBlockModalOpen] = useState(false);
    const [newBlockTitle, setNewBlockTitle] = useState('');
    const [notes, setNotes] = useState([]);
    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
    const [currentNote, setCurrentNote] = useState({ blockTitle: '', noteBody: '' });

    const newBlockRef = useRef(null);
    const createdBlock = useRef(false);

    useEffect(() => {
        const loadCompanyData = async () => {
            try {
                const cachedData = window.localStorage.getItem(`companyData_${fullUrl}`);
                if (cachedData) {
                    const result = JSON.parse(cachedData);
                    setUrls(result.base_url ? [result.base_url] : []);
                    setSections(result.block_list || []);
                    setCompanyName(result.company_name ? result.company_name : '');
                    setDateSourced(result.date_sourced ? new Date(result.date_sourced * 1000).toLocaleDateString() : '');
                    extractEmailsFromMainWebsite(result.block_list || []);
                    setTags(result.tags || []);
                    const tempNotes = await getNotes(fullUrl);
                    setNotes(tempNotes);
                    const tempFrontendBlocks = await getFrontendBlocks(fullUrl);
                    setFrontendSections(tempFrontendBlocks);
                } else {
                    const result = await getIndividualCompanyData(fullUrl);
                    setUrls(result.base_url ? [result.base_url] : []);
                    setSections(result.block_list || []);
                    setCompanyName(result.company_name ? result.company_name : '');
                    setDateSourced(result.date_sourced ? new Date(result.date_sourced * 1000).toLocaleDateString() : '');
                    extractEmailsFromMainWebsite(result.block_list || []);
                    setTags(result.tags || []);
                    const tempNotes = await getNotes(fullUrl);
                    setNotes(tempNotes);
                    const tempFrontendBlocks = await getFrontendBlocks(fullUrl);
                    setFrontendSections(tempFrontendBlocks);
                    window.localStorage.setItem(`companyData_${fullUrl}`, JSON.stringify(result));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        loadCompanyData();
    }, [fullUrl]);

    const extractEmailsFromMainWebsite = (sections) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
        const mainWebsiteSection = sections.find(section => section.block_title === 'Main Website');
        if (mainWebsiteSection && mainWebsiteSection.block_text) {
            const matchedEmails = mainWebsiteSection.block_text.match(emailRegex);
            setEmails(matchedEmails || []); // Set the emails if found, otherwise an empty array
        } else {
            setEmails([]); // Set to an empty array if the section or text is not available
        }
    };

    useEffect(() => {
        if (createdBlock.current && newBlockRef.current) {
            newBlockRef.current.scrollIntoView({ behavior: 'smooth' });
            createdBlock.current = false;
        }
    }, [sections, frontendSections]);

    const handleTagChange = (e) => {
        setTag(e.target.value);
    };

    const handleSubmitTag = () => {
        setTags([...tags, tag]);
        closeTagModal();
    };

    const openCreateTagModal = () => {
        setIsCreateTagModalOpen(true);
    };

    const closeTagModal = () => {
        setIsCreateTagModalOpen(false);
        setTag('');
    };

    const openRemoveModal = (tag) => {
        setTagToRemove(tag);
        setIsRemoveModalOpen(true);
    };

    const closeRemoveModal = () => {
        setIsRemoveModalOpen(false);
        setTagToRemove(null);
    };

    const handleConfirmRemoveTag = () => {
        setTags(tags.filter(t => t !== tagToRemove));
        closeRemoveModal();
    };

    const openCreateBlockModal = () => {
        setIsCreateBlockModalOpen(true);
    };

    const closeCreateBlockModal = () => {
        setIsCreateBlockModalOpen(false);
        setNewBlockTitle('');
    };

    const handleNewBlockTitleChange = (e) => {
        setNewBlockTitle(e.target.value);
    };

    const handleSubmitNewBlock = () => {
        const existingTitles = sections.map(section => section.block_title);
        if (existingTitles.includes(newBlockTitle)) {
            alert('A block with this title already exists. Please choose a different title.');
            return;
        }
        setFrontendSections([...frontendSections, { blockTitle: newBlockTitle, blockBody: '' }]);
        closeCreateBlockModal();
    };

    const handleFrontendSectionChange = (index, newBody) => {
        const newFrontendSections = [...frontendSections];
        newFrontendSections[index].blockBody = newBody;
        setFrontendSections(newFrontendSections);
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const handleNotesClick = (blockTitle) => {
        let note = notes.find(note => note.blockTitle === blockTitle);
        if (!note) {
            note = { blockTitle, noteBody: '' };
            setNotes([...notes, note]);
        }
        setCurrentNote(note);
        setIsNotesModalOpen(true);
    };

    const handleNoteChange = (e) => {
        const newNoteBody = e.target.value;
        setCurrentNote(prevNote => ({ ...prevNote, noteBody: newNoteBody }));
        setNotes(prevNotes => prevNotes.map(note =>
            note.blockTitle === currentNote.blockTitle ? { ...note, noteBody: newNoteBody } : note
        ));
    };

    const closeNotesModal = () => {
        setIsNotesModalOpen(false);
    };

    const handleSaveChanges = async () => {
        try {
            await saveNotes(fullUrl, notes);
            await saveFrontendBlocks(fullUrl, frontendSections);
            await saveTagList(fullUrl, tags.join(','));
            alert('Changes saved successfully');
        } catch (error) {
            console.error('Error saving notes:', error);
            alert('Failed to save notes');
        }
    };

    const handleRegatherChanges = () => {
        const confirmed = window.confirm('Are you sure you want to regather changes? You may lose your changes. This action cannot be undone.');
        if (confirmed) {
            gatherInformation(fullUrl);
        }
    };

    const handleExportToPDF = () => {
        alert('Feature coming soon...');
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-white min-h-screen">
            {/* Left Section (1/3 of the page) */}
            <div className="md:w-1/3 p-6 rounded overflow-y-auto md:sticky md:top-0 h-screen custom-scrollbar">
                <h1 className="text-2xl font-bold mb-4">Company Description</h1>

                {/* Date Sourced */}
                <div className="mb-4 bg-gray-800 p-4 rounded">
                    <h3 className="text-lg font-bold">Date Sourced:</h3>
                    <p>{dateSourced}</p>
                </div>

                {/* Company URLs */}
                <div className="mb-4 bg-gray-800 p-4 rounded">
                    <h3 className="text-lg font-bold">Company URLs:</h3>
                    {urls.map((url, index) => (
                        <p key={index}><a target="blank_" href={url} className="text-blue-400 hover:underline">{url}</a></p>
                    ))}
                </div>

                {/* Tags */}
                <div className="mb-4 bg-gray-800 p-4 rounded">
                    <h3 className="text-lg font-bold">Tags:</h3>
                    <div className="flex flex-wrap gap-2">
                        {tags.map((tag, index) => (
                            <div key={index} className="relative bg-gray-600 text-gray-200 px-2 py-1 rounded flex items-center">
                                {tag}
                                <span
                                    onClick={() => openRemoveModal(tag)}
                                    className="ml-2 text-red-400 cursor-pointer"
                                >
                                    &times;
                                </span>
                            </div>
                        ))}
                        <button onClick={openCreateTagModal} className="bg-gray-600 text-gray-200 px-2 py-1 rounded">Create Tag</button>
                    </div>
                </div>

                {/* Executive Emails */}
                <div className="mb-4 bg-gray-800 p-4 rounded">
                    <h3 className="text-lg font-bold">Emails:</h3>
                    <ul className="list-disc ml-4">
                        {emails.map((email, index) => (
                            <li key={index}>
                                <a href={`mailto:${email}`} className="text-blue-400 hover:underline">
                                    {email}
                                </a>
                            </li>
                        ))}
                    </ul>

                </div>
            </div>

            {/* Right Section (2/3 of the page) */}
            <div className="md:w-2/3 p-6 overflow-y-auto border-l border-gray-700 mb-2">
                <h2 className="text-2xl font-bold mb-4">{companyName}</h2>

                {/* Action Buttons */}
                <div className="flex justify-between mb-4 space-x-2">
                    <button onClick={() => window.location.href = '/home'} className="w-1/5 bg-gray-700 text-gray-200 px-4 py-2 rounded hover:bg-gray-600">Go Back</button>
                    <button onClick={handleExportToPDF} className="w-1/5 bg-gray-700 text-gray-200 px-4 py-2 rounded hover:bg-gray-600">Coming Soon</button>
                    <button onClick={() => { openCreateBlockModal(); createdBlock.current = true; }} className="w-1/5 bg-gray-700 text-gray-200 px-4 py-2 rounded hover:bg-gray-600">Create Block</button>
                    <button 
                        onClick={handleSaveChanges}
                        className="w-1/5 bg-gray-700 text-gray-200 px-4 py-2 rounded hover:bg-gray-600"
                    >
                        Save Changes
                    </button>
                    <button onClick={handleRegatherChanges} className="w-1/5 bg-gray-700 text-gray-200 px-4 py-2 rounded hover:bg-gray-600">Regather Changes</button>
                </div>

                {/* Analysis Sections */}
                <div className="space-y-6">
                    {sections.map((section, index) => (
                        <div key={index} className="bg-gray-800 p-4 rounded shadow-lg" ref={index === sections.length - 1 ? newBlockRef : null}>
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="text-lg font-bold">{section.block_title}</h4>
                                <div className="flex space-x-2">
                                    <button 
                                        className="bg-blue-600 text-gray-200 px-3 py-1 rounded hover:bg-blue-500"
                                        onClick={() => handleCopy(section.block_text)}
                                    >
                                        Copy
                                    </button>
                                    <button 
                                        className="bg-yellow-600 text-gray-200 px-3 py-1 rounded hover:bg-yellow-500"
                                        onClick={() => handleNotesClick(section.block_title)}
                                    >
                                        Notes
                                    </button>
                                </div>
                            </div>
                            <div 
                                className="w-full p-2 bg-gray-800 text-gray-200 rounded mt-2 border border-gray-700 overflow-hidden whitespace-pre-wrap"
                                style={{ minHeight: '3em' }}
                            >
                                {section.block_text}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Frontend Blocks */}
                <div className="space-y-6">
                    {frontendSections.map((frontendBlock, index) => (
                        <div key={index} className="bg-gray-800 p-4 rounded shadow-lg mt-6" ref={index === frontendSections.length - 1 ? newBlockRef : null}>
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="text-lg font-bold">{frontendBlock.blockTitle}</h4>
                                <div className="flex space-x-2">
                                    <button 
                                        className="bg-blue-600 text-gray-200 px-3 py-1 rounded hover:bg-blue-500"
                                        onClick={() => handleCopy(frontendBlock.blockBody)}
                                    >
                                        Copy
                                    </button>
                                    <button 
                                        className="bg-yellow-600 text-gray-200 px-3 py-1 rounded hover:bg-yellow-500"
                                        onClick={() => handleNotesClick(frontendBlock.blockTitle)}
                                    >
                                        Notes
                                    </button>
                                </div>
                            </div>
                            <textarea
                                className="w-full p-2 bg-gray-800 text-gray-200 rounded mt-2 border border-gray-700 overflow-hidden whitespace-pre-wrap"
                                value={frontendBlock.blockBody}
                                onChange={(e) => handleFrontendSectionChange(index, e.target.value)}
                                placeholder="Make changes here..."
                                style={{ minHeight: '3em' }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Modal for Creating Tag */}
            {isCreateTagModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-gray-800 p-6 rounded shadow-lg w-1/3">
                        <h3 className="text-lg font-bold mb-4">Create Tag</h3>
                        <textarea 
                            className="w-full p-2 mb-4 bg-gray-700 text-gray-200 rounded" 
                            rows="4" 
                            value={tag} 
                            onChange={handleTagChange}
                            placeholder="Enter your new tag"
                        ></textarea>
                        <div className="flex justify-end space-x-2">
                            <button onClick={closeTagModal} className="bg-gray-600 text-gray-200 px-4 py-2 rounded hover:bg-gray-500">Cancel</button>
                            <button onClick={handleSubmitTag} className="bg-blue-600 text-gray-200 px-4 py-2 rounded hover:bg-blue-500">Submit</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Notes */}
            {isNotesModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-gray-800 p-6 rounded shadow-lg w-11/12 flex flex-col">
                        <div className="flex-1 flex w-full">
                            <div className="w-2/3 pr-4">
                                <h3 className="text-lg font-bold mb-4">Original Text for {currentNote.blockTitle}</h3>
                                <div className="bg-gray-700 p-4 rounded text-gray-200 overflow-y-scroll h-full max-h-96 whitespace-pre-wrap">
                                    {sections.find(section => section.block_title === currentNote.blockTitle)?.block_text ||
                                    sections.find(section => section.block_title === currentNote.blockTitle)?.blockBody ||
                                    sections.find(section => section.block_title === currentNote.blockTitle)?.block_body ||
                                    frontendSections.find(frontendBlock => frontendBlock.blockTitle === currentNote.blockTitle)?.block_text ||
                                    frontendSections.find(frontendBlock => frontendBlock.blockTitle === currentNote.blockTitle)?.blockBody ||
                                    frontendSections.find(frontendBlock => frontendBlock.blockTitle === currentNote.blockTitle)?.block_body}
                                </div>
                            </div>
                            <div className="w-1/3 pl-4">
                                <h3 className="text-lg font-bold mb-4">Notes</h3>
                                <textarea 
                                    className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4 h-full max-h-96"
                                    value={currentNote.noteBody}
                                    onChange={handleNoteChange}
                                    placeholder="Enter your notes"
                                ></textarea>
                            </div>
                        </div>
                        <div className="flex justify-end space-x-2 mt-4">
                            <button onClick={closeNotesModal} className="bg-gray-600 text-gray-200 px-4 py-2 rounded hover:bg-gray-500">Close</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Creating Block */}
            {isCreateBlockModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-gray-800 p-6 rounded shadow-lg w-1/3">
                        <h3 className="text-lg font-bold mb-4">Create New Block</h3>
                        <input 
                            type="text"
                            className="w-full p-2 mb-4 bg-gray-700 text-gray-200 rounded" 
                            value={newBlockTitle}
                            onChange={handleNewBlockTitleChange}
                            placeholder="Enter block title"
                        />
                        <div className="flex justify-end space-x-2">
                            <button onClick={closeCreateBlockModal} className="bg-gray-600 text-gray-200 px-4 py-2 rounded hover:bg-gray-500">Cancel</button>
                            <button onClick={handleSubmitNewBlock} className="bg-blue-600 text-gray-200 px-4 py-2 rounded hover:bg-blue-500">Save</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Confirming Tag Removal */}
            {isRemoveModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-gray-800 p-6 rounded shadow-lg w-1/3">
                        <h3 className="text-lg font-bold mb-4">Confirm Tag Removal</h3>
                        <p className="text-gray-200 mb-4">Are you sure you want to remove the tag: {tagToRemove}?</p>
                        <div className="flex justify-end space-x-2">
                            <button onClick={closeRemoveModal} className="bg-gray-600 text-gray-200 px-4 py-2 rounded hover:bg-gray-500">Cancel</button>
                            <button onClick={handleConfirmRemoveTag} className="bg-red-600 text-gray-200 px-4 py-2 rounded hover:bg-red-500">Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IndividualCompany;
