import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUser, checkUser, checkToken, sendApiKey } from "./AuthService";

// Function to generate a random string of specified length
const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default function SignUp() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    apiKey: '',
    marketArea: '',
    minTicket: '',
    maxTicket: '',
    vcName: '',
    linkedinLink: '',
    password: '',
    confirmPassword: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Generate and validate API key when the component mounts
    const generateUniqueApiKey = async () => {
      let apiKey;
      let isUnique = false;
      while (!isUnique) {
        apiKey = generateRandomString(32);
        isUnique = await checkToken(apiKey);
      }
      setFormData((prevState) => ({
        ...prevState,
        apiKey
      }));
    };

    generateUniqueApiKey();

    if (checkUser()) {
      navigate('/verify');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    // Cast minTicket and maxTicket to integers
    const formDataWithInts = {
      ...formData,
      minTicket: parseInt(formData.minTicket, 10),
      maxTicket: parseInt(formData.maxTicket, 10),
    };

    try {
      await createUser(formDataWithInts);
      await sendApiKey(formData.apiKey);
      window.location.reload(); // Reload the page to update the Navbar
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <form onSubmit={handleSubmit} className="bg-gray-800 rounded-lg shadow-lg p-8 md:w-1/2 lg:w-2/3 xl:w-1/2 mt-10 mb-10">
        <h1 className="text-3xl text-white text-center mb-9">Sign up for a new account</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="firstName" className="text-white mb-2">First Name</label>
            <input id="firstName" type="text" value={formData.firstName} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col">
            <label htmlFor="lastName" className="text-white mb-2">Last Name</label>
            <input id="lastName" type="text" value={formData.lastName} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="phone" className="text-white mb-2">Phone Number</label>
            <input id="phone" type="text" value={formData.phone} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="email" className="text-white mb-2">Email</label>
            <input id="email" type="text" value={formData.email} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="vcName" className="text-white mb-2">VC Name</label>
            <input id="vcName" type="text" value={formData.vcName} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="marketArea" className="text-white mb-2">Market Area</label>
            <input id="marketArea" type="text" value={formData.marketArea} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="minTicket" className="text-white mb-2">Minimum Ticket</label>
            <input id="minTicket" type="number" value={formData.minTicket} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="maxTicket" className="text-white mb-2">Maximum Ticket</label>
            <input id="maxTicket" type="number" value={formData.maxTicket} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>

          <div className="flex flex-col mt-3 col-span-1 md:col-span-2">
            <label htmlFor="linkedinLink" className="text-white mb-2">LinkedIn Profile</label>
            <input id="linkedinLink" type="text" value={formData.linkedinLink} onChange={handleChange} className="w-full px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
          </div>
        </div>

        <div className="flex flex-col mt-3">
          <label htmlFor="password" className="text-white mb-2">Password</label>
          <input id="password" type="password" value={formData.password} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
        </div>

        <div className="flex flex-col mt-3">
          <label htmlFor="confirmPassword" className="text-white mb-2">Confirm Password</label>
          <input id="confirmPassword" type="password" value={formData.confirmPassword} onChange={handleChange} className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring-2 focus:ring-blue-600" />
        </div>

        <div className="mt-6 flex justify-center">
          <button type="submit" className="w-full max-w-xs bg-gray-900 text-white hover:bg-gray-700 rounded-lg px-4 py-2">
            Sign Up
          </button>
        </div>

        <div className="mt-3 text-center">
          <span className="text-white">
            Already have an account? <a href="/login" className="text-blue-500">Log In</a>
          </span>
        </div>
      </form>
    </div>
  );
}
