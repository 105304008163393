import React, { useState, useEffect } from 'react';
import Parse from 'parse';
import { updateProfile } from './ProfileService';

const Profile = () => {
    const [currentUser, setCurrentUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        linkedin: '',
        vcName: '',
        vcDomain: '',
        minTicketSize: '',
        maxTicketSize: '',
        clicksSaved: 'Coming Soon',
        copyPastesSaved: 'Coming Soon',
        timeSaved: 'Coming Soon',
        companiesAnalyzed: 'Coming Soon'
    });

    const [formData, setFormData] = useState({
        linkedin: '',
        minTicketSize: '',
        maxTicketSize: ''
    });

    useEffect(() => {
        const user = Parse.User.current();
        if (user) {
            const userData = {
                firstName: user.get('firstName'),
                lastName: user.get('lastName'),
                email: user.get('email'),
                linkedin: user.get('linkedinLink'),
                vcName: user.get('vcName'),
                vcDomain: user.get('marketArea'),
                minTicketSize: user.get('minTicket'),
                maxTicketSize: user.get('maxTicket'),
                clicksSaved: 'Coming Soon',
                copyPastesSaved: 'Coming Soon',
                timeSaved: 'Coming Soon',
                companiesAnalyzed: 'Coming Soon',
            };
            setCurrentUser(userData);
            setFormData({
                linkedin: userData.linkedin,
                minTicketSize: userData.minTicketSize,
                maxTicketSize: userData.maxTicketSize
            });
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDiscardChanges = () => {
        window.location.reload();
    };

    const handleSaveChanges = async () => {
        try {
            await updateProfile({
                linkedin: formData.linkedin,
                minTicketSize: formData.minTicketSize,
                maxTicketSize: formData.maxTicketSize
            });
            alert('Profile changes saved!');
        } catch (error) {
            alert('Failed to save profile changes.');
        }
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-white min-h-screen">
            {/* Left Section (1/3 of the page) */}
            <div className="w-full md:w-1/3 p-6 rounded md:overflow-y-auto md:sticky md:top-0 custom-scrollbar">
                <h2 className="text-2xl font-bold mb-4">Stats</h2>
                <div className="mb-4 p-4 bg-gray-800 rounded shadow-lg">
                    <div className="mb-4 p-4 bg-gray-700 rounded shadow">
                        <p className="text-lg mb-2">Number of clicks saved: {currentUser.clicksSaved}</p>
                    </div>
                    <div className="mb-4 p-4 bg-gray-700 rounded shadow">
                        <p className="text-lg mb-2">Number of copy/pastes saved: {currentUser.copyPastesSaved}</p>
                    </div>
                    <div className="mb-4 p-4 bg-gray-700 rounded shadow">
                        <p className="text-lg mb-2">Time saved: {currentUser.timeSaved}</p>
                    </div>
                    <div className="mb-4 p-4 bg-gray-700 rounded shadow">
                        <p className="text-lg">Number of companies analyzed: {currentUser.companiesAnalyzed}</p>
                    </div>
                </div>
            </div>

            {/* Right Section (2/3 of the page) */}
            <div className="w-full md:w-2/3 p-6 overflow-y-auto border-l border-gray-700">
                <h2 className="text-2xl font-bold mb-4">Profile</h2>
                <div className="space-y-4">
                    <div className="p-4 bg-gray-800 rounded shadow-lg">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <input
                                type="text"
                                name="firstName"
                                value={currentUser.firstName}
                                readOnly
                                className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                                placeholder="First name"
                            />
                            <input
                                type="text"
                                name="lastName"
                                value={currentUser.lastName}
                                readOnly
                                className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                                placeholder="Last name"
                            />
                        </div>
                        <input
                            type="email"
                            name="email"
                            value={currentUser.email}
                            readOnly
                            className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                            placeholder="Email"
                        />
                        <input
                            type="text"
                            name="linkedin"
                            value={formData.linkedin}
                            onChange={handleChange}
                            className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                            placeholder="LinkedIn link"
                        />
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <input
                                type="text"
                                name="vcName"
                                value={currentUser.vcName}
                                readOnly
                                className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                                placeholder="VC name or Angel"
                            />
                            <input
                                type="text"
                                name="vcDomain"
                                value={currentUser.vcDomain}
                                readOnly
                                className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                                placeholder="VC domain"
                            />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <input
                                type="number"
                                name="minTicketSize"
                                value={formData.minTicketSize}
                                onChange={handleChange}
                                className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                                placeholder="Min ticket size ($)"
                            />
                            <input
                                type="number"
                                name="maxTicketSize"
                                value={formData.maxTicketSize}
                                onChange={handleChange}
                                className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                                placeholder="Max ticket size ($)"
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleDiscardChanges}
                                className="bg-red-800 text-gray-200 px-4 py-2 rounded hover:bg-red-700"
                            >
                                Discard Changes
                            </button>
                            <button
                                onClick={handleSaveChanges}
                                className="bg-green-800 text-gray-200 px-4 py-2 rounded hover:bg-green-700"
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
