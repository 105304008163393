import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCompanyList, gatherInformation, deleteCompany } from "./HomeService.js";

const Home = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [tags, setTags] = useState([]);
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [sortOption, setSortOption] = useState('date-sourced-reverse');
    const [link, setLink] = useState('');
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        fetchCompanies();
        const interval = setInterval(() => {
            setCompanies(prev => [...prev]);
        }, 1000); // Update progress bars every second
        return () => clearInterval(interval); // Clear interval on unmount
    }, []);

    const fetchCompanies = async () => {
        try {
            const newCompanyList = await getCompanyList();
            setCompanies(newCompanyList || []);
        } catch (error) {
            console.error('Error fetching company list:', error);
            setCompanies([]);
        }
    };

    const handleCompanyClick = (baseUrl) => {
        const companyUrl = baseUrl.replace(/^(https?:\/\/)?/, '').replace(/\/$/, '');
        window.location.href = `/home/${companyUrl}`;
    };

    const handleAddTag = () => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setSelectedTags([...selectedTags, newTag]);
        }
        setNewTag('');
        setIsTagModalOpen(false);
    };

    const handleRemoveTag = (tag) => {
        setTags(tags.filter(t => t !== tag));
        setSelectedTags(selectedTags.filter(t => t !== tag));
    };

    const handleScrape = async () => {
        let formattedLink = link;

        alert('Gathering information is in progress. New searches may take a moment to appear.');

        if (link.startsWith('www.')) {
            formattedLink = `http://${link}`;
        } else if (!link.startsWith('https://') && !link.startsWith('http://')) {
            alert('Please enter a valid URL starting with http://, https://, or www.');
            return;
        }

        try {
            await gatherInformation(formattedLink);
            window.location.reload(); // Reload page after gathering information
        } catch (error) {
            console.error('Error gathering company information:', error);
        }
    };

    const unixToDate = (unixTime) => {
        const date = new Date(unixTime * 1000);
        return date.toLocaleDateString("en-US");
    };

    const isCompleted = (unixTime) => {
        const currentTime = Math.floor(Date.now() / 1000);
        return currentTime - unixTime > 90; // 1.5 minutes = 90 seconds
    };

    const getProgressPercentage = (unixTime) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const elapsed = currentTime - unixTime;
        return Math.min((elapsed / 90) * 100, 100); // Cap at 100% for 90 seconds
    };

    const filteredCompanies = companies.filter(company => {
        const matchesTags = selectedTags.length === 0 || selectedTags.every(tag => 
            company.tags.some(companyTag => companyTag.toLowerCase() === tag.toLowerCase())
        );
        const matchesDate = (!startDate || new Date(company.date_sourced * 1000) >= startDate) && 
                            (!endDate || new Date(company.date_sourced * 1000) <= endDate);
        const matchesSearchQuery = searchQuery === '' || 
            company.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            company.founder_list.some(founder => founder.toLowerCase().includes(searchQuery.toLowerCase())) ||
            company.base_url.toLowerCase().includes(searchQuery.toLowerCase());

        return matchesTags && matchesDate && matchesSearchQuery;
    });

    const sortedCompanies = [...filteredCompanies].sort((a, b) => {
        if (sortOption === 'alphabetical') {
            return a.company_name?.localeCompare(b.company_name) || 0;
        } else if (sortOption === 'reverse-alphabetical') {
            return b.company_name?.localeCompare(a.company_name) || 0;
        } else if (sortOption === 'date-sourced') {
            return new Date(a.date_sourced * 1000) - new Date(b.date_sourced * 1000);
        } else if (sortOption === 'date-sourced-reverse') {
            return new Date(b.date_sourced * 1000) - new Date(a.date_sourced * 1000);
        }
        return 0;
    });

    const handleDeleteClick = async (company) => {
        if (window.confirm(`Are you sure you want to delete ${company.company_name}?`)) {
            try {
                await deleteCompany(company.base_url);
                window.location.reload(); // Reload page after deleting the company
            } catch (error) {
                console.error(`Error deleting ${company.company_name}:`, error);
            }
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        fetchCompanies(); // Reload page when searching for a company
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-white min-h-screen">
            <div className="w-full md:w-1/4 p-6 rounded md:overflow-y-auto md:sticky md:top-0 custom-scrollbar">
                <h2 className="text-2xl font-bold mb-4">Filter Options</h2>

                <div className="mb-4 bg-gray-800 p-4 rounded">
                    <h3 className="text-lg font-bold">Date Range:</h3>
                    <div className="space-y-2">
                        <div className="flex items-center">
                            <span className="mr-2 w-12">From:</span>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="bg-gray-700 text-gray-200 rounded"
                            />
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2 w-12">To:</span>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className="bg-gray-700 text-gray-200 rounded"
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-4 bg-gray-800 p-4 rounded">
                    <h3 className="text-lg font-bold">Tags:</h3>
                    <div className="flex flex-wrap gap-2">
                        {tags.map((tag, index) => (
                            <div key={index} className="relative bg-gray-600 text-gray-200 px-2 py-1 rounded flex items-center">
                                {tag}
                                <span onClick={() => handleRemoveTag(tag)} className="ml-2 text-red-400 cursor-pointer">&times;</span>
                            </div>
                        ))}
                        <button onClick={() => setIsTagModalOpen(true)} className="bg-gray-600 text-gray-200 px-2 py-1 rounded">Add Tag</button>
                    </div>
                </div>
            </div>

            <div className="w-full md:w-3/4 p-6 overflow-y-auto border-l border-gray-700">
                <div className="mb-4">
                    <h2 className="text-2xl font-bold mb-2">Gather Startup Information</h2>
                    <div className="flex items-center">
                        <input
                            type="text"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            className="w-3/4 p-2 bg-gray-700 text-gray-200 rounded mr-2"
                            placeholder="Enter link (e.g. https://www.desync.ai)"
                        />
                        <button
                            onClick={handleScrape}
                            className="bg-blue-600 text-gray-200 px-4 py-2 rounded hover:bg-blue-500"
                        >
                            Gather
                        </button>
                    </div>
                </div>

                <hr className="border-gray-700 mb-4" />

                <h2 className="text-2xl font-bold mb-4">Company Directory</h2>

                <div className="flex justify-between mb-4">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch} // Reload page when searching
                        className="w-1/2 p-2 bg-gray-700 text-gray-200 rounded mr-2"
                        placeholder="Search by name, founder, or website"
                    />
                    <select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                        className="w-1/2 p-2 bg-gray-700 text-gray-200 rounded"
                    >
                        <option value="alphabetical">Sort by Alphabetical</option>
                        <option value="reverse-alphabetical">Sort by Reverse Alphabetical</option>
                        <option value="date-sourced">Sort by Date Sourced (Oldest to Newest)</option>
                        <option value="date-sourced-reverse">Sort by Date Sourced (Newest to Oldest)</option>
                    </select>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-800 rounded-lg">
                        <thead>
                            <tr>
                                <th className="py-2 px-2 bg-gray-700">Name</th>
                                <th className="py-2 px-2 bg-gray-700">Date</th>
                                <th className="py-2 px-2 bg-gray-700 hidden sm:table-cell">Founders</th>
                                <th className="py-2 px-2 bg-gray-700 hidden sm:table-cell">Website</th>
                                <th className="py-2 px-2 bg-gray-700">Completed</th>
                                <th className="py-2 px-2 bg-gray-700">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedCompanies.map((company, index) => (
                                <tr 
                                    key={index} 
                                    className="cursor-pointer hover:bg-gray-700" 
                                    onClick={() => handleCompanyClick(company.base_url)}
                                >
                                    <td className="py-2 px-2">{company.company_name}</td>
                                    <td className="py-2 px-2">{unixToDate(company.date_sourced)}</td>
                                    <td className="py-2 px-2 hidden sm:table-cell">{company.founder_list.join(', ')}</td>
                                    <td className="py-2 px-2 hidden sm:table-cell">{company.base_url}</td>
                                    <td className="py-2 px-2 text-center">
                                        {isCompleted(company.date_sourced) ? (
                                            '✓'
                                        ) : (
                                            <div className="w-full bg-gray-400 rounded-full h-4">
                                                <div
                                                    className="bg-blue-600 h-4 rounded-full"
                                                    style={{ width: `${getProgressPercentage(company.date_sourced)}%` }}
                                                ></div>
                                            </div>
                                        )}
                                    </td>
                                    <td className="py-2 px-2 text-center">
                                        <button
                                            onClick={(e) => { e.stopPropagation(); handleDeleteClick(company); }}
                                            className="text-white bg-transparent hover:bg-red-600 rounded-full p-1 z-10 relative"
                                            style={{ border: 'none' }}
                                        >
                                            🗑️
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isTagModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-gray-800 p-6 rounded shadow-lg w-1/3">
                        <h3 className="text-lg font-bold mb-4">Add Tag</h3>
                        <input
                            type="text"
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            className="w-full p-2 mb-4 bg-gray-700 text-gray-200 rounded"
                            placeholder="Enter new tag"
                        />
                        <div className="flex justify-end space-x-2">
                            <button onClick={() => setIsTagModalOpen(false)} className="bg-gray-600 text-gray-200 px-4 py-2 rounded hover:bg-gray-500">Cancel</button>
                            <button onClick={handleAddTag} className="bg-blue-600 text-gray-200 px-4 py-2 rounded hover:bg-blue-500">Add</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
