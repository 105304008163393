import React, { useState, useEffect } from 'react';
import { getBugReports, postBugReport } from "./BugsService.js"

const Bugs = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [previousReports, setPreviousReports] = useState([]);
      
    useEffect(() => {
        const fetchBugReports = async () => {
            const reports = await getBugReports();
            if (reports) {
                setPreviousReports(reports.map(report => ({
                    title: report.get('bugTitle'),
                    description: report.get('bugDescription'),
                    resolved: report.get('resolved')
                })));
            }
        };

        fetchBugReports();
    }, []);

    const handleSubmit = async () => {
        if (!title || !description) {
            alert('Title and Description cannot be empty!');
            return;
        }

        try {
            const newReport = await postBugReport(title, description);
            alert('Bug report submitted!');
            setPreviousReports([...previousReports, {
                title: newReport.get('bugTitle'),
                description: newReport.get('bugDescription'),
                resolved: newReport.get('resolved')
            }]);
            setTitle('');
            setDescription('');
        } catch (error) {
            console.error("Error while submitting bug report:", error);
            alert('Error submitting bug report.');
        }
    };

    return (
        <div className="flex flex-col md:flex-row bg-gray-900 text-white min-h-screen">
            {/* Right Section (1/3 of the page) */}
            <div className="w-full md:w-1/3 p-6 rounded md:overflow-y-auto md:sticky md:top-0 custom-scrollbar">
                <h2 className="text-xl font-bold mb-4">Previous Reports</h2>
                <div className="space-y-4">
                    {previousReports.map((report, index) => (
                        <div key={index} className="bg-gray-800 p-4 rounded shadow-lg">
                            <h3 className="text-lg font-bold">{report.title}</h3>
                            <p>{report.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Left Section (2/3 of the page) */}
            <div className="w-full md:w-2/3 p-6 overflow-y-auto border-l border-gray-700">
                <h2 className="text-2xl font-bold mb-4">Report a Bug</h2>
                <div className="mb-4">
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                        placeholder="Title"
                    />
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full p-2 bg-gray-700 text-gray-200 rounded mb-4"
                        placeholder="Description"
                        rows="4"
                    />
                    <button
                        onClick={handleSubmit}
                        className="bg-green-800 text-gray-200 px-4 py-2 rounded hover:bg-green-700"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Bugs;
